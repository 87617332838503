import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpClientService } from "src/app/shared/services/http-client/http-client.service";

@Injectable({ providedIn: "root" })
export class ConsentRepo {
    controllerName: string = "consent";
    constructor(private http: HttpClientService) { }

    hasConsented(cvrNumber: number): Observable<boolean> {
        return this.http
            .get(`${this.controllerName}?cvrNumber=${cvrNumber}`)
            .pipe(map((res: boolean) => res));
    }

    getConsentUrlWithReturnUrl(cvrNumber: number): Observable<string> {
        return this.http
            .get(`${this.controllerName}/url?cvrNumber=${cvrNumber}`, {
                responseType: "text",
            })
            .pipe(map((redirectUrl: string) => this.addReturnUrlTo(redirectUrl, 'app/home')));
    }

    addReturnUrlTo(redirectUrl: string, returnUrl: string) {
        const baseUrl = window.location.origin;
        return redirectUrl.replace(/(?:^|\W)\{0\}(?:$|\W)/, `=${baseUrl}/${returnUrl}`);
    }
    addRedirectUrl(url: string): string {
        const baseUrl = window.location.origin;
        return url.replace(/(?:^|\W)\{0\}(?:$|\W)/, `=${baseUrl}`);
    }
}
