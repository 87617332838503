import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from 'src/config/consts/app-config.token';
import { AppConfig } from 'src/config/model/app-config.interface';
import { NavigationItem } from '../../components/navigation/navigation-item.interface';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(@Inject(APP_CONFIG) private appConfig: AppConfig) {}

  getNavigationItems(): NavigationItem[] {
    return [
      {
        routerLink: 'app/home',
        title: 'Start'
      },
      {
        routerLink: 'app/data',
        title: 'DATAGRUNDLAG',
      },
      {
        routerLink: 'app/climate-footprint',
        title: 'climateFootprint.title',
      },
      {
        routerLink: "https://klimatjek.mejeri.dk",
        title: 'MEJERIBRUGETS KLIMATJEK',
        icon: 'open_in_new',
        isExternalRouterLink: true
      }
    ];
  }
}
