import { HttpClient } from '@angular/common/http';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APP_CONFIG } from 'src/config/consts/app-config.token';
import { AppConfig } from 'src/config/model/app-config.interface';
import { NotificationService } from '../../modules/notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {
  onError = new EventEmitter<Error>();
  
  private apiUrl: string;

  constructor(
    @Inject(APP_CONFIG) appConfig: AppConfig, 
    private http: HttpClient, 
    private notification: NotificationService) {
    this.apiUrl = appConfig.config.apiUrl;
  }

  get(url: string, options?: any): Observable<any> {
    if (!options) {
      options = {};
    }
    return this.http.get(`${this.apiUrl}${url}`, options).pipe(catchError((err) => {
      this.showError(err);
      throw (err);
    }));
  }

  post(url: string, body: any, options?: any): Observable<any> {
    if (!options) {
      options = {};
    }

    return this.http.post(`${this.apiUrl}${url}`, body, options).pipe(catchError((err) => {
      this.showError(err);
      throw (err);
    }));
  }

  private showError(err) {
    this.notification.showError(err?.error?.ErrorMessage);
  }
}
