import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class gtmService {

  constructor() {}

  getAgroId(value) {
    let dataLayer = (window as any).dataLayer || [];

    dataLayer.push({
      'event': "userData",
      'user_id' : value
    });
  }
}
