import { APP_CONFIG } from '../consts/app-config.token';
import { AppConfig } from '../model/app-config.interface';

/**
 * Loads the environment configuration file.
 * The configuration file differs for each environment.
 * This should be done before application bootstrap.
 */
export function loadAppConfig() {
  return new Promise<AppConfig>((resolve, reject) => {
    const req = new XMLHttpRequest();
    if (req.overrideMimeType) {
      req.overrideMimeType('application/json');
    }
    req.open('GET', 'app-config.json', true);
    req.onreadystatechange = () => {
      if (req.readyState === 4 && req.status === 200) {
        window.localStorage.setItem(APP_CONFIG.toString(), req.responseText);
        const config = JSON.parse(req.response) as AppConfig;
        if (!config.config.production) {
          console.log('readyState' + req.responseText);
        }
        resolve(config);
      }
    };
    req.onerror = (err) => {
      reject(err);
    };
    req.send(null);
  });
}
