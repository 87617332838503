<app-header (menuPressed)="sidenav.open()"></app-header>
<div class="content-area">
  <mat-sidenav-container>
    <mat-sidenav #sidenav mode="over" closed [autoFocus]="false">
      <app-navigation [navigationItems]="navItems" (menuItemPressed)="sidenav.close()"></app-navigation>
    </mat-sidenav>
    <mat-sidenav-content id="scroll-container" [ngStyle]="{ 'overflow-y': (disableScroll$ | async) ? 'hidden' : ''}">
      <div class="outlet">
        <mat-progress-bar class="loading" *ngIf="loading$ | async" mode="indeterminate"></mat-progress-bar>
        <router-outlet style="display: none"></router-outlet>
        <app-footer></app-footer>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>