import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { BusinessEntity } from '../../models/dto/business-entity.interface';
import { HttpClientService } from '../../services/http-client/http-client.service';
import { Repo } from '../repo.interface';

@Injectable({
  providedIn: 'root'
})
export class BusinessEntityRepo implements Repo<BusinessEntity> {
  controllerName = 'BusinessEntity';

  constructor(private http: HttpClientService) { }

  get(cvr: string): Observable<BusinessEntity> {
    if (isNaN(parseInt(cvr))) {
      throw throwError(`Invalid cvr: ${cvr}`);
    }
    const query = `?cvrNumber=${cvr}`;
    return this.http.get(`${this.controllerName}/GetBusinessEntity${query}`);
  }

  search(cvr: string, name: string): Observable<BusinessEntity[]> {
    const query = `?cvrNumber=${cvr ?? ''}&name=${name ?? ''}`;
    return this.http.get(`${this.controllerName}/SearchBusinessEntities${query}`);
  }
}
