import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthorizationService } from '../../services/authorization/authorization.service';
import { AppStateService } from '../../state/app-state/app-state.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private appState: AppStateService, private auth: AuthorizationService) { }

  canActivate(): Observable<boolean> {
    return this.appState.userLoggedIn.value$.pipe(tap((loggedIn) => {
      if (!loggedIn) {
        this.auth.startSigninMainWindow();
      }
    }));
  }
}
