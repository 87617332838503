import { Injectable } from "@angular/core";

export const ShortcutKeys = {
  s: "KeyS",
  F10: "F10",
  ArrowDown: 'ArrowDown',
  Backspace: 'Backspace',
  Enter: 'Enter'
};

export interface IShortcutCallBack {
  callBack: any,
  key: string,
  ctrl: boolean
}

@Injectable()
export class ShortcutService {

  private listening = false;
  private callBacks: IShortcutCallBack[] = [];

  constructor() { }

  unsubscribe(shortcut: IShortcutCallBack) {
    this.callBacks = this.callBacks.filter(cb => cb != shortcut);
    if (!this.callBacks.length) 
    this.stopListening();
  }

  private stopListening() {
      this.listening = false;
      document.onkeydown = null;
  }
   
 /**
 * 
 * @param {number} shortcut - is of type IShortcutCallBack 
 *  Example use: 
 *  {
 *    callBack: ()=> this.onSave(),
 *    ctrl: true,
 *    key: ShortcutKeys.s
 *  }
 */
  subcribe(shortcut: IShortcutCallBack) {
    this.callBacks.push(shortcut);
    this.startListening();
  }

  private startListening() {
    if (this.listening) return;
    this.listening = true;

    const t = this;
    document.onkeydown = function (e) {      
      const cbs = t.callBacks.filter(cb => cb.key == e.code /* code is not case sensitive */ && cb.ctrl == e.ctrlKey);

      if (!cbs.length) {
        return;
      }

      for (const cb of cbs) {
        cb.callBack();
      }

      e.stopPropagation();
      e.returnValue = false;
    };
  }
}
