import { ChangeDetectionStrategy, Component } from "@angular/core";
import { gtmService } from "src/app/shared/services/gtm/gtm.service";
import { EventLogType } from "./shared/models/requests/event-log-request.interface";
import { EventLogRepo } from "./shared/repos/event-log/event-log.repo";
import { AppStateService } from "./shared/state/app-state/app-state.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {


  constructor(
    private gtmService: gtmService,
    private appState: AppStateService,
    private eventLogRepo: EventLogRepo
  ) {
    let loggedIndUserId = appState.userLoggedIn.value ? appState.userState?.value?.userName :"NotLoggedIn"
    if(appState.userLoggedIn.value)
      {
        this.eventLogRepo.publishEventLog({
          message: "User logged in, refreshed page or switched year scope",
          type: EventLogType.UserLogIn,
        });
      }
    this.gtmService.getAgroId(loggedIndUserId);
  }
}
