import { Inject, Injectable } from '@angular/core';
import { LogConfig } from './model/log-config.interface';
import { LogFields } from './model/log-fields.interface';
import { LogLevel } from './model/log-level.interface';
import { UserInfo } from './model/user-info.interface';
import { LOG_CONFIG } from './tokens/log-config.token';
import { USER_INFO } from './tokens/user-info.token';

@Injectable()
export class LogService {
  private userId = '';
  private APP_NAME = '';
  private APP_FIELD = 'Application';
  private ENV_FIELD = 'Environment';
  private VERSION_FIELD = 'Version';
  private USER_NAME_FIELD = 'UserName';
  private ELAPSED_MS_FIELD = 'ElapsedMilliseconds';
  private REQUEST_PATH_FIELD = 'RequestPath';
  private CORRELATION_ID_FIELD = 'ActivityId';
  private CONNECTION_ID_FIELD = 'TransportConnectionId';

  constructor(
    @Inject(LOG_CONFIG) private config: LogConfig,
    @Inject(USER_INFO) private userInfo: UserInfo,
  ) {
    this.APP_NAME = config.appName;
    this.userId = this.userInfo.userId;
  }

  logHttpInfo(info: any, elapsedTime: number, requestPath: string, correlationId: string) {
    const logFields: LogFields = {
      appVersion: this.config.versionnumber,
      environment: this.config.environment,
      userId: this.userId,
      correlationId,
      connectionId: '',
      requestPath,
      elapsedTime,
    };

    this.log(LogLevel.Info, `${info}`, logFields);
  }

  logError(error: string, correlationId?: string) {
    const logFields: LogFields = {
      appVersion: this.config.versionnumber,
      environment: this.config.environment,
      userId: this.userId,
      correlationId: correlationId ? correlationId : '',
      connectionId: '',
      requestPath: '',
      elapsedTime: 0,
    };

    this.log(LogLevel.Error, error, logFields);
  }

  logInfo(info: any) {
    const logFields: LogFields = {
      appVersion: this.config.versionnumber,
      environment: this.config.environment,
      userId: this.userId,
      requestPath: '',
      correlationId: '',
      connectionId: '',
      elapsedTime: 0,
    };

    this.log(LogLevel.Info, info, logFields);
  }

  logWsInfo(info: any, correlationId: string, connectionId: string) {
    const logFields: LogFields = {
      appVersion: this.config.versionnumber,
      environment: this.config.environment,
      userId: this.userId,
      correlationId,
      connectionId,
      requestPath: '',
      elapsedTime: 0,
    };

    this.log(LogLevel.Info, info, logFields);
  }

  logWsError(error: string, correlationId: string, connectionId: string) {
    const logFields: LogFields = {
      appVersion: this.config.versionnumber,
      environment: this.config.environment,
      userId: this.userId,
      correlationId,
      connectionId,
      requestPath: '',
      elapsedTime: 0,
    };

    this.log(LogLevel.Error, error, logFields);
  }

  private log(level: LogLevel, error: string, data: LogFields) {
    const message = this.buildLogString(level, error, data);
    const xobj = new XMLHttpRequest();
    xobj.open('POST', `${this.config.logUrl}`, true);
    xobj.send(message);
  }

  private buildLogString(level: LogLevel, error: string, data: LogFields): string {
    const dateTime = new Date().toISOString();
    const date = dateTime.split('T')[0];

    const levelFormatted = `"level":"${level}"`;
    const message = `"message":"${(error + '').replace(/(\r\n|\n|\r)/gm, ' ').replace(/(")/gm, '')}"`;
    const messageTemplate = this.getMessageTemplate();
    const fields = this.getFields(data);
    const timestamp = `"@timestamp":"${dateTime}"`;
    const _index = `"_index": "logstash-${date}"`;
    const _type = `"_type":"logevent"`;

    const index = `{"index":{ ${_index}, ${_type}}}`;
    const body = `{${timestamp}, ${levelFormatted}, ${messageTemplate}, ${message}, ${fields}}`;

    return `${index},\n${body}\n`;
  }

  private getMessageTemplate() {
    const fields: string[] = [this.ENV_FIELD, this.VERSION_FIELD, this.USER_NAME_FIELD, this.ELAPSED_MS_FIELD, this.REQUEST_PATH_FIELD, this.CORRELATION_ID_FIELD, this.CONNECTION_ID_FIELD];
    const template = fields.reduce((previous, next) => `${previous} - {${next}}`, `{${this.APP_FIELD}}`);

    return `"messageTemplate": "${template}"`;
  }

  private getFields(data: LogFields) {
    const logFields: { key: string; value: string }[] = [
      {
        key: this.ENV_FIELD,
        value: `"${data.environment}"`
      },
      {
        key: this.VERSION_FIELD,
        value: `"${data.appVersion}"`
      },
      {
        key: this.USER_NAME_FIELD,
        value: `"${data.userId}"`
      },
      {
        key: this.ELAPSED_MS_FIELD,
        value: `${data.elapsedTime}`
      },
      {
        key: this.REQUEST_PATH_FIELD,
        value: `"${data.requestPath}"`
      },
      {
        key: this.CORRELATION_ID_FIELD,
        value: `"${data.correlationId}"`
      },
      {
        key: this.CONNECTION_ID_FIELD,
        value: `"${data.connectionId}"`
      }
    ];

    const fields = logFields.reduce((previous, current) => `${previous}, "${current.key}": ${current.value}`, `"${this.APP_FIELD}": "${this.APP_NAME}"`);

    return `"fields": {${fields}}`;
  }
}
