import { Inject, Injectable, OnDestroy } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { fromEvent, Subscription } from 'rxjs';
import { WINDOW_REF } from 'src/config/consts/window-ref.token';
import { WindowRef } from 'src/config/model/window-ref.interface';

@Injectable({
  providedIn: 'root'
})
export class OnlineGuard implements CanActivate, OnDestroy {
  private subs = new Subscription();
  online: boolean;

  constructor(@Inject(WINDOW_REF) private windowRef: WindowRef, private router: Router) {
    this.subs.add(
      fromEvent(this.windowRef, 'offline').pipe().subscribe((e) => {
        this.online = false;
        this.router.navigate(['app/offline']);
      }));
    this.subs.add(
      fromEvent(this.windowRef, 'online').pipe().subscribe((e) => {
        this.online = true;
        this.router.navigate(['']);
      }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  canActivate(): boolean {
    return this.online;
  }
}
