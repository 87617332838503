// tslint:disable:directive-selector
import { Directive, HostBinding } from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { BaseBtnDirective } from '../base/base-btn.directive';

@Directive({
  selector: '[lf-trans-btn]',
  providers: [MatRipple]
})
export class LfTransBtnDirective extends BaseBtnDirective {

  @HostBinding('class') elementClass = 'base-btn lf-trans-btn';

  constructor(ripple: MatRipple) {
    super(ripple);
  }
}
