import { NgModule } from '@angular/core';
import { getWindow, WINDOW_REF } from './consts/utils.const';
import { LogService } from './Services/log/log.service';
import { ShortcutService } from './Services/shortcut/shortcut.service';
import { WindowUtilService } from './Services/window-util/window-util.service';

@NgModule({
  providers: [
    LogService,
    ShortcutService,
    WindowUtilService,
    {
      provide: WINDOW_REF,
      useFactory: getWindow
    },
  ],
})

export class AngularUtilsModule { }
