import { Language } from './language.interface';


export const appLanguages = [
  {
    shortKey: 'da',
    longKey: 'da-DK',
    flag: 'assets/images/countries/da.png',
    name: 'Dansk'
  },
  {
    shortKey: 'en',
    longKey: 'en-EN',
    flag: 'assets/images/countries/en.png',
    name: 'English'
  }
] as Language[];
