import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/shared/services/storage/local-storage/local-storage.service';

export const SWITCH_USER_REQUEST = 'SWITCH_USER_REQUEST';

@Component({
  selector: 'app-logged-out',
  templateUrl: './logged-out.component.html',
  styleUrls: ['./logged-out.component.scss']
})
export class LoggedOutComponent implements OnInit {
  constructor(private localStorage: LocalStorageService, private router: Router) { }

  ngOnInit(): void {
    const requestPending = this.localStorage.getItem(SWITCH_USER_REQUEST);
    if (requestPending) {
      this.localStorage.setItem(SWITCH_USER_REQUEST, false);
      this.router.navigate(['/']);
    }
  }
}
