import { registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";
import localeDa from "@angular/common/locales/da";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgChartsModule } from "ng2-charts";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { Language } from "./shared/components/language-picker/language.interface";
import {
  AppInitService,
  init_app
} from "./shared/services/app-init/app-init.service";
import { ErrorHandlerService } from "./shared/services/error-handler/error-handler.service";
import { gtmService } from "./shared/services/gtm/gtm.service";
import { HttpInterceptorService } from "./shared/services/http-client/http-interceptor/http-interceptor.service";
import { NavigationService } from "./shared/services/navigation/navigation.service";
import { SharedModule } from "./shared/shared.module";
import { AppStateService } from "./shared/state/app-state/app-state.service";
import { AppShellModule } from "./views/app-shell/app-shell.module";
import { FrontpageComponent } from "./views/frontpage/frontpage.component";
import { LoggedOutComponent } from "./views/logged-out/logged-out.component";
import { NotFoundComponent } from "./views/not-found/not-found.component";
import { NotValidatedComponent } from "./views/not-validated/not-validated.component";
import { OfflineComponent } from "./views/offline/offline.component";

registerLocaleData(localeDa);

// AoT requires an exported function for factories
export function TranslateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function getLocale(appState: AppStateService): Language {
  return appState.selectedLanguage.value;
}

@NgModule({
  declarations: [
    AppComponent,
    LoggedOutComponent,
    NotValidatedComponent,
    NotFoundComponent,
    OfflineComponent,
    FrontpageComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AppShellModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgChartsModule,
  ],
  providers: [
    gtmService,
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppInitService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    NavigationService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
