import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { entries, omit, values } from 'lodash-es';
import keyBy from 'lodash-es/keyBy';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, finalize, map, take, tap } from 'rxjs/operators';
import { StateItem } from 'src/app/libraries/seges-web-utils/state-item/state-item.class';
import { BusinessEmission } from 'src/app/shared/models/dto/business-emission.interface';
import { ClimateFootprint } from 'src/app/shared/models/dto/climate-footprint.interface';
import { DataSourceType } from 'src/app/shared/models/dto/data-source.interface';
import { InputRule } from 'src/app/shared/models/dto/input-rule.interface';
import { Dictionary } from 'src/app/shared/models/misc/dictionary.interface';
import { EmissionRepo } from 'src/app/shared/repos/emission/emission.repo';
import { InputRulesRepo } from 'src/app/shared/repos/input-rule/input-rules.repo';
import { LocalStorageService } from 'src/app/shared/services/storage/local-storage/local-storage.service';
import { SessionStorageService } from 'src/app/shared/services/storage/session-storage/session-storage.service';
import { AppStateService } from 'src/app/shared/state/app-state/app-state.service';
import { EmissionTag } from 'src/app/views/climate-footprint/emissions/model/emission-tag.enum';
import { ConsentRepo } from 'src/app/views/consent/consent.repo';
import { YearScope } from '../../components/header/year-scope-picker/year-scope.interface';
import { UserInputVM } from '../../modules/data-edit/model/user-input-vm.interface';

@Injectable({
  providedIn: 'root'
})
export class DataStateService implements OnDestroy {
  anyInputsSaving = new StateItem<boolean>(false);
  emissionUpToDate = new StateItem<boolean>(true);
  anyInputsInvalid = new StateItem<boolean>(false);
  isEditingScenario = new StateItem<boolean>(false);
  isSavingInput = new StateItem<Dictionary<boolean>>({});
  isSavingSource = new StateItem<Dictionary<boolean>>({});
  scenarioEmissionUpToDate = new StateItem<boolean>(true);
  climateFootprint = new StateItem<ClimateFootprint>(null);
  businessEmission = new StateItem<BusinessEmission>(null);
  inputRules = new StateItem<Dictionary<InputRule>>(null, 'INPUT_RULES', this.localStorage);
  metaDataCrops = new StateItem<DataSourceType>(null, 'CROP_META_DATA', this.sessionStorage);
  metaDataHousing = new StateItem<DataSourceType>(null, 'HOUSING_META_DATA', this.sessionStorage);
  metaDataPoultryHousing = new StateItem<DataSourceType>(null, 'POULTRY_HOUSING_META_DATA', this.sessionStorage);

  epicEmissionTags$: Observable<string[]> = this.climateFootprint.value$.pipe(filter(c => !!c), map(c => [...c.emissionSources.map(es => es.tag), EmissionTag.EnergyAndMachinework]));
  yearScope$: Observable<YearScope> = this.appState.selectedYearScope.value$;

  private scenarioParentRoute = 'climate-footprint';
  private subs = new Subscription();

  constructor(
    private router: Router,
    private appState: AppStateService,
    private rulesRepo: InputRulesRepo,
    private emissionRepo: EmissionRepo,
    private activatedRoute: ActivatedRoute,
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService,
    private consentRepo: ConsentRepo
  ) {
    this.subs.add(
      this.activatedRoute.fragment.subscribe((fragment) => {
        this.isEditingScenario.value = (fragment && this.router.url.includes(this.scenarioParentRoute)) ?? false;
      }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initializeData() {

    const selectedCvr = this.appState.delegatedUserState.value ? this.appState.delegatedUserState.value.cvrNumber : this.appState.userState.value.cvr;
    if (this.appState.userState?.value?.isAdmin) {
      combineLatest([
        this.climateFootprint.value$.pipe(filter(c => c === null)),
        this.appState.businessEntity.value$.pipe(filter(b => b !== null))])
        .pipe(
          take(1)).subscribe(([climateFootprint, businessEntity]) => {
            this.appState.loading.value = true;
            combineLatest([
              this.rulesRepo.get(),
              this.emissionRepo.getEmissionsOverview(businessEntity.id)])
              .pipe(take(1), finalize(() => this.appState.loading.value = false))
              .subscribe(([rules, overview]) => {
                this.climateFootprint.value = omit(overview, 'businessEmission');
                this.inputRules.value = keyBy(rules, r => String(r.id));
                this.businessEmission.value = overview.businessEmission;
              });
          });
    }
    else if (!this.climateFootprint.value) {
      combineLatest([
        this.climateFootprint.value$.pipe(filter(c => c === null)),
        this.appState.businessEntity.value$.pipe(filter(b => b !== null)),
        this.consentRepo.hasConsented(parseInt(selectedCvr))])
        .pipe(
          take(1)).subscribe(([climateFootprint, businessEntity, consented]) => {
            this.appState.loading.value = true;
            combineLatest([
              this.rulesRepo.get(),
              this.emissionRepo.getEmissionsOverview(businessEntity.id)])
              .pipe(take(1), finalize(() => this.appState.loading.value = false))
              .subscribe(([rules, overview]) => {
                this.climateFootprint.value = omit(overview, 'businessEmission');
                this.inputRules.value = keyBy(rules, r => String(r.id));
                this.businessEmission.value = overview.businessEmission;
                if (!consented)
                  this.router.navigate(["app/data"]);
              });
          });
    }

    if (!this.emissionUpToDate.value) {
      this.recalculate().subscribe();
    }
  }

  recalculate() {
    return new Observable<BusinessEmission>((observer) => {
      this.businessEmission.value$.pipe(filter(be => be !== null)).pipe(take(1)).subscribe((b) => {
        this.emissionRepo.getEmission(b.businessEntityId, b.climateFootPrintId)
          .pipe(take(1), tap((be) => {
            this.businessEmission.value = be;
            this.emissionUpToDate.value = true;
          })).subscribe(((be) => {
            observer.next(be);
            observer.complete();
          }));
      });
    });
  }

  updateInputsInConfig(userInputs: UserInputVM[]) {
    const dataLevel = userInputs[0]?.dataLevel;
    const paramSetId = userInputs[0]?.parameterInput.parameterInputSetId;
    const inputIds = userInputs.map(i => i.id);
    this.climateFootprint.value.emissionSources.forEach(ems => {
      ems.emissionSources.forEach(emss => {
        if (emss.userInputs?.[dataLevel] && emss.userInputs[dataLevel][paramSetId]) {
          emss.userInputs[dataLevel][paramSetId] = [...emss.userInputs[dataLevel][paramSetId].filter(i => !inputIds.includes(i.id)), ...userInputs];
        }
      });
    });
  }

  removeInputsFromConfig(userInputs: UserInputVM[]) {
    const dataLevel = userInputs[0]?.dataLevel;
    const paramSetId = userInputs[0]?.parameterInput.parameterInputSetId;
    const idsToDelete = userInputs.map(ui => ui.id);
    this.climateFootprint.value.emissionSources.forEach(ems => {
      ems.emissionSources.forEach(emss => {
        if (emss.userInputs?.[dataLevel] && emss.userInputs[dataLevel][paramSetId]) {
          emss.userInputs[dataLevel][paramSetId] = emss.userInputs[dataLevel][paramSetId].filter(i => !idsToDelete.includes(i.id));
        }
      });
    });
  }

  setCardLoadingState(cardKey: string, to: boolean) {
    this.isSavingInput.value[cardKey] = to;
    this.isSavingInput.value = { ...this.isSavingInput.value };
    this.setSourceLoadingState(this.isSavingInput.value);
    this.setAnyInputsSaving(this.isSavingInput.value);
  }

  clearCardLoadingState() {
    this.isSavingInput.value = {};
  }

  markEmissionsDirty() {
    this.isEditingScenario.value ? this.scenarioEmissionUpToDate.value = false : this.emissionUpToDate.value = false;
  }

  resetData() {
    this.businessEmission.value = null;
    this.climateFootprint.value = null;
  }

  getEditedValue(userInput: UserInputVM, prop: 'Value' | 'StrValue' | 'ItemId' = 'Value'): number | string {
    return this.isEditingScenario.value ? userInput?.[`scenario${prop}`] : userInput?.[`entered${prop}`];
  }

  setEditedValue(userInput: UserInputVM, newValue: number | string, prop: 'Value' | 'StrValue' | 'ItemId' = 'Value'): UserInputVM {
    const property = this.isEditingScenario.value ? `scenario${prop}` : `entered${prop}`;
    userInput[property] = newValue;
    return userInput;
  }

  private setSourceLoadingState(isSavingInput: Dictionary<boolean>) {
    const sourceMap = {};
    entries(isSavingInput).forEach(([key, value]) => {
      const sourceKey = key.split('-')[0];
      sourceMap[sourceKey] = value || !!sourceMap[sourceKey];
    });
    this.isSavingSource.value = { ...sourceMap };
  }

  private setAnyInputsSaving(isSavingInput: Dictionary<boolean>) {
    let isSaving = false;
    for (const inputSaving of values(isSavingInput)) {
      if (inputSaving) {
        isSaving = true;
        break;
      }
    }
    this.anyInputsSaving.value = isSaving;
  }
}
