<div class="not-validated-root">
  <div class="center-content">
      <h1>Du har ikke adgang til ESGreenTool</h1>
      <div class="esgreen">Hvis du er <b>bedriftsejer,</b> kan det skyldes at:</div>
      <ul>
        <li>du ikke har abonnement til ESGreenTool. Du kan se priser og bestille abonnement <a (click)="goToOrderSubscription()">her</a></li>
        <li>dit BrugerID ikke er CVR-valideret</li>
        <li>dit BrugerID er valideret til et andet CVR nr. end det, gødningsregnskabet er indsendt på</li>
        <li>dine data fra gødningsregnskabet ikke findes i ESGreenTool. Vi oplever i sjældne tilfælde, at data ikke kan findes.</li>
      </ul>
    <div class="actions">
      <button lf-btn (click)="goToOrderSubscription()" class="btn">
        Bestil abonnement
      </button>
      <button lf-btn (click)="goToMyProfile()" class="btn">
        Gå til CVR-validering
      </button>
      <button lf-btn (click)="goToDelegationHelp()" class="btn">
        Hjælp til CVR-validering
      </button>
    </div>
    <br/>
    <br/>
    <div class="esgreen">Eller log ind med en <a (click)="switchUser()">anden bruger</a></div>
    <br>
    <div class="esgreen">Hvis du er <b>rådgiver,</b> kan det skyldes at:</div>
    <ul>
      <li>du mangler delegering fra en bedrift. Anmod en eller flere bedriftsejere om at delegere til dig eller til rådgivningsvirksomhedens klimagruppe</li>
      <li>bedriftsejere mangler at afgive samtykke i ESGreenTool - kontakt bedriftsejer</li>
    </ul>
    <div class="esgreen">Bliv klogere på <a target="blank" href="https://help.seges.dk/esgreen-tool/delegering-og-samtykke-paa-esgreen-tool">delegering og samtykke.</a></div>
    <br>
    <div class="esgreen">Har du brug for hjælp? Kontakt SEGES Innovations kundecenter på tlf. 7015 5015</div>
  </div>
</div>