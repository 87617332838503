import { Injectable } from '@angular/core';
import { LanguageService } from '../../components/language-picker/language.service';
import { AppStateService } from '../../state/app-state/app-state.service';
import { AuthorizationService } from '../authorization/authorization.service';
import { AppUpdaterService } from '../updater/app-updater.service';

export function init_app(appInitService: AppInitService) {
  return () => appInitService.init();
}

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  constructor(
    private lang: LanguageService,
    private appState: AppStateService,
    private appUpdater: AppUpdaterService,
    private authService: AuthorizationService
  ) { }

  // This is the method you want to call at bootstrap
  // Important: It should return a Promise
  public async init(): Promise<void> {
    await this.authService.initialize();
    await this.appState.initialize();
    this.appUpdater.initialize();
    return this.lang.init();
  }
}
