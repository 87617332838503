import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { APP_CONFIG } from "src/config/consts/app-config.token";
import { OIDC_CONFIG } from "src/config/consts/oidc-config.token";
import { SCROLL_CONTAINER } from "src/config/consts/scroll-container.token";
import { WINDOW_REF } from "src/config/consts/window-ref.token";
import { getConfig } from "src/config/functions/get-app.config.fn";
import { getScrollContainer } from "src/config/functions/get-scroll-container.fn";
import { getOidc as getOidcConfig } from "src/config/functions/load-oidc.config.fn";
import { getWindow } from "src/config/functions/load-window.fn";
import { getLocale } from "../app.module";
import { LOG_CONFIG } from "../libraries/angular-utils/Services/log/tokens/log-config.token";
import { USER_INFO } from "../libraries/angular-utils/Services/log/tokens/user-info.token";
import { AngularUtilsModule } from "../libraries/angular-utils/angular-utils.module";
import { LFButtonModule } from "../libraries/lf-buttons/lf-button.module";
import { LsDirectivesModule } from "../libraries/ls-directives/lsdirectives.module";
import { LsPipesModule } from "../libraries/ls-pipes/lspipes.module";
import { SELECTED_LOCALE } from "../libraries/ls-pipes/tokens/selected-locale.token";
import { CookiePoliciesComponent } from "../views/cookie-policy/cookie-policy.component";
import { CardComponent } from "./components/card/card.component";
import { CenterContentComponent } from "./components/center-content/center-content.component";
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { ExpandArrowComponent } from "./components/expand-arrow/expand-arrow.component";
import { ExpanderCardComponent } from "./components/expander-card/expander-card.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { SettingsComponent } from "./components/header/settings/settings.component";
import { UserInfoComponent } from "./components/header/user-info/user-info.component";
import { YearScopePickerComponent } from './components/header/year-scope-picker/year-scope-picker.component';
import { InfoIconComponent } from "./components/info-icon/info-icon.component";
import { LanguagePickerComponent } from "./components/language-picker/language-picker.component";
import { NavigationComponent } from "./components/navigation/navigation.component";
import { SectionProgressComponent } from "./components/section-progress/section-progress.component";
import { SidenavHeaderComponent } from "./components/sidenav-header/sidenav-header.component";
import { getLogConfig } from "./functions/get-log-config.fn";
import { getUserInfo } from "./functions/get-user-info.fn";
import { AppTranslateModule } from "./modules/app-translate/app-translate.module";
import { MaterialModule } from "./modules/material/material.module";
import { NotificationModule } from "./modules/notification/notification.module";
import { AppStateService } from "./state/app-state/app-state.service";

@NgModule({
  declarations: [
    CardComponent,
    HeaderComponent,
    FooterComponent,
    UserInfoComponent,
    SettingsComponent,
    InfoIconComponent,
    NavigationComponent,
    ExpandArrowComponent,
    ExpanderCardComponent,
    SidenavHeaderComponent,
    ConfirmDialogComponent,
    CenterContentComponent,
    LanguagePickerComponent,
    SectionProgressComponent,
    YearScopePickerComponent,
    CookiePoliciesComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    NotificationModule,
    AppTranslateModule,
    LsDirectivesModule,
    LFButtonModule,
    LsPipesModule,
    RouterModule,
    FormsModule,
    AngularUtilsModule,
  ],
  exports: [
    CardComponent,
    HeaderComponent,
    FooterComponent,
    UserInfoComponent,
    SettingsComponent,
    InfoIconComponent,
    NavigationComponent,
    ExpandArrowComponent,
    ExpanderCardComponent,
    SidenavHeaderComponent,
    CenterContentComponent,
    LanguagePickerComponent,
    SectionProgressComponent,
    ReactiveFormsModule,
    AppTranslateModule,
    LsDirectivesModule,
    NotificationModule,
    MaterialModule,
    LFButtonModule,
    LsPipesModule,
    FormsModule,
    YearScopePickerComponent,
    CookiePoliciesComponent,
  ],
  providers: [
    {
      provide: APP_CONFIG,
      useFactory: getConfig,
    },
    {
      provide: OIDC_CONFIG,
      useFactory: getOidcConfig,
    },
    {
      provide: WINDOW_REF,
      useFactory: getWindow,
    },
    {
      provide: SCROLL_CONTAINER,
      useFactory: getScrollContainer,
    },
    {
      provide: SELECTED_LOCALE,
      useFactory: getLocale,
      deps: [AppStateService],
    },
    {
      provide: LOG_CONFIG,
      useFactory: getLogConfig,
    },
    {
      provide: USER_INFO,
      useFactory: getUserInfo,
      deps: [AppStateService],
    },
  ],
})
export class SharedModule { }
