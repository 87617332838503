import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppUpdaterService implements OnDestroy {
  private subscription: Subscription;

  constructor(private swUpdate: SwUpdate, private snackbar: MatSnackBar, private translate: TranslateService) { }

  initialize() {
    if (this.swUpdate.isEnabled) {
      this.subscription = this.swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
          map(e => !!e)
      )
      .subscribe(() => {
          const snack = this.snackbar.open(
            this.translate.instant('updater.newVersion'),
            this.translate.instant('updater.update'), {
            panelClass: ['info-snackbar'],
            verticalPosition: 'top',
          });
          snack
            .onAction()
            .subscribe(() => {
              window.location.reload();
            });
      });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
