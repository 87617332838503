import { Subject } from 'rxjs';

export function EmitOnDestroy() {
  return function (constructor) {
    const origDestroy = constructor.prototype.ngOnDestroy;
    constructor.prototype.unsubscribe = new Subject<void>();
    // Emit unsubscribe event on destroy, to be used with takeUntil operator
    constructor.prototype.ngOnDestroy = function () {
      constructor.prototype.unsubscribe.next();
      if (origDestroy) {
        origDestroy.apply();
      }
    };
  };
}


