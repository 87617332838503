import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { indexOf } from 'lodash-es';
import { merge } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { EmitOnDestroy } from 'src/app/libraries/angular-utils/operators/take-until-destroy/emit-on-destroy.decorator';
import { takeUntilDestroy } from 'src/app/libraries/angular-utils/operators/take-until-destroy/take-until-destroy.operator';
import { AppStateService } from 'src/app/shared/state/app-state/app-state.service';
import { WINDOW_REF } from 'src/config/consts/window-ref.token';
import { WindowRef } from 'src/config/model/window-ref.interface';
import { YearScope } from './year-scope.interface';
import { yearScopes } from './year-scope.options';

@EmitOnDestroy()
@Component({
  selector: 'app-year-scope-picker',
  templateUrl: './year-scope-picker.component.html',
  styleUrls: ['./year-scope-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YearScopePickerComponent implements OnInit {
  @Output() yearScopeChanged = new EventEmitter<YearScope>();
  selectedYearScope: YearScope;
  yearScopes: YearScope[] = [];
  selectedYearScopeIndex: number;

  constructor(@Inject(WINDOW_REF) private windowRef: WindowRef, private appState: AppStateService) {
    merge(
      this.appState.businessEntity.value$,
      this.appState.selectedBusiness.value$
    )
    .pipe(distinctUntilChanged(), filter(business => !! business), takeUntilDestroy(this))
    .subscribe((business) => {
      const supportedYearScopes = yearScopes.filter(ys => business.yearScopeKeys.some(businessYearScope => businessYearScope === ys.shortKey));
      this.yearScopes = supportedYearScopes;
    });
  }

  ngOnInit(): void {
    this.setSelectedYearScopeIndex();
  }

  onSelectedTabChange(e: MatTabChangeEvent){
    this.selectedYearScope = this.yearScopes[e.index];
    this.yearScopeChanged.emit(this.selectedYearScope);
    this.appState.selectedYearScope.value = this.selectedYearScope;
    this.windowRef.location.reload();
  }

  private setSelectedYearScopeIndex() {
    this.selectedYearScope = this.appState.selectedYearScope.value;
    this.selectedYearScopeIndex = indexOf(this.yearScopes.map(y => y.year), this.selectedYearScope.year);
  }
}
