import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorComponent } from './error/error.component';
import { SuccessComponent } from './success/success.component';


@Injectable()
export class NotificationService {

  constructor(private snackBar: MatSnackBar) { }

  showError(err: string): void {
    this.snackBar.openFromComponent(ErrorComponent, {
      duration: 8000,
      data: { errorMessage: err }
    });
  }

  showSuccess(message?: string): void {
    this.snackBar.openFromComponent(SuccessComponent, {
      duration: 3000,
      data: { message }
    });
  }
}
