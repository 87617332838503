import { Inject, Optional, Pipe, PipeTransform } from '@angular/core';
import { Locale } from '../model/locale.interface';
import { SELECTED_LOCALE } from '../tokens/selected-locale.token';

@Pipe({ name: 'numberToString' })
export class NumberToStringPipe implements PipeTransform {

  constructor(@Optional() @Inject(SELECTED_LOCALE) private locale: Locale) {
    this.locale = this.locale ?? {
      longKey: 'da-DK',
      shortKey: 'da'
    }
  }

  transform(value: number, decimals: number = 0, minDecimals?: number): string {
    value = this.round(value, decimals);

    if (value === undefined || value === null) {
      return null;
    }

    if (minDecimals === undefined || minDecimals === null) {
      minDecimals = decimals;
    }

    // -0 is not a real value
    if (value <= 0 && value > -1) {
      value = Number(String(value).replace('-', ''));
    }

    const result = value.toLocaleString(this.locale.shortKey, {
      minimumFractionDigits: minDecimals,
      maximumFractionDigits: decimals
    });
    return result;
  }

  private round(n: number, decimals: number): number {
    if (n === null || n === undefined) {
      return null;
    }

    return Math.round(n * Math.pow(10, decimals)) / Math.pow(10, decimals);
  }
}

