import { Injectable, Inject } from '@angular/core';
import { WindowRef, WINDOW_REF } from '../../consts/utils.const';

@Injectable()
export class WindowUtilService {
  constructor(@Inject(WINDOW_REF) private windowRef: WindowRef) {
  }

  // Get query value by field name e.g. getQueryString('id') from id=145 will return 145
  getQueryString(field: string): string | null {
    const reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
    const result = reg.exec(this.windowRef.location.href);
    return result ? result[1] : null;
  }

  // Scrolls smoothly to element with id
  async scrollIntoView(id: string) {
    await this.sleep(200); // wait for dom
    const document = this.windowRef.document;
    const e = document.getElementById(id);
    if (e) {
      e.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  // Focus element with Id
  async focusElement(id: string, delay: boolean = false) {
    if (delay) {
      await this.sleep(200); // wait for dom
    }
    const e = this.windowRef.document.getElementById(id);
    if (e) {
      e.focus();
    }
  }

  // awaitable sleep
  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // Provided csv content will be downloaded as file.
  downloadCsvFile(fileName: string, csvContent: string) {
    var encodedUri = encodeURI("data:text/csv;charset=utf-8,\uFEFF" + csvContent);
    var a = document.createElement('a');
    a.setAttribute("href", encodedUri);
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  // Create file from downloaded blob
  downloadFile(fileName: string, blob: Blob) {
    const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    
  }
}
