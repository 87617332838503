import { NgModule } from '@angular/core';
import { AnimalNumberPipe } from './pipes/animal-number.pipe';
import { AnimalRunningNumberPipe } from './pipes/animal-running-number.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { DateTimeFormatPipe } from './pipes/date-time-format.pipe';
import { IncludesPipe } from './pipes/includes.pipe';
import { MassConversionPipe } from './pipes/mass-conversion.pipe';
import { NullToStringPipe } from './pipes/null-to-string.pipe';
import { NumberToStringPipe } from './pipes/number-to-string.pipe';
import { SearchTextHighlightPipe } from './pipes/search-text-highlight.pipe';
import { TakePipe } from './pipes/take.pipe';

@NgModule({
  declarations: [
    SearchTextHighlightPipe,
    AnimalRunningNumberPipe,
    DateTimeFormatPipe,
    NumberToStringPipe,
    MassConversionPipe,
    AnimalNumberPipe,
    NullToStringPipe,
    DateFormatPipe,
    IncludesPipe,
    TakePipe,
  ],
  exports: [
    SearchTextHighlightPipe,
    AnimalRunningNumberPipe,
    DateTimeFormatPipe,
    NumberToStringPipe,
    MassConversionPipe,
    AnimalNumberPipe,
    NullToStringPipe,
    DateFormatPipe,
    IncludesPipe,
    TakePipe
  ]
})

export class LsPipesModule { }
