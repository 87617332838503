import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth/auth.guard';
import { UserConsentedGuard } from './shared/guards/consent/user-consented.guard';
import { OnlineGuard } from './shared/guards/online/online.guard';
import { UserCvrValidatedGuard } from './shared/guards/user-cvr-validated/user-cvr-validated.guard';
import { AppShellComponent } from './views/app-shell/app-shell.component';
import { CookiePoliciesComponent } from './views/cookie-policy/cookie-policy.component';
import { FrontpageComponent } from './views/frontpage/frontpage.component';
import { LoggedOutComponent } from './views/logged-out/logged-out.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { NotValidatedComponent } from './views/not-validated/not-validated.component';
import { OfflineComponent } from './views/offline/offline.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'app',
    pathMatch: 'full'
  },
  {
    path: 'app',
    canActivate: [AuthGuard],
    component: AppShellComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        canActivate: [OnlineGuard, UserCvrValidatedGuard, UserConsentedGuard],
        component: FrontpageComponent
      },
      {
        path: 'data',
        canActivate: [OnlineGuard, UserCvrValidatedGuard, UserConsentedGuard],
        loadChildren: () => import('./views/data/data.module').then(m => m.DataModule)
      },
      {
        path: 'climate-footprint',
        canActivate: [OnlineGuard, UserCvrValidatedGuard, UserConsentedGuard],
        loadChildren: () => import('./views/climate-footprint/climate-footprint.module').then(m => m.ClimateFootprintModule)
      },
      {
        path: 'product-footprint',
        canActivate: [OnlineGuard, UserCvrValidatedGuard, UserConsentedGuard],
        loadChildren: () => import('./views/product-footprint/product-footprint.module').then(m => m.ProductFootprintModule)
      },
      {
        path: 'delegation',
        loadChildren: () => import('./views/delegation/delegation.module').then(m => m.DelegationModule)
      },
      {
        path: 'consent',
        loadChildren: () => import('./views/consent/consent.module').then(m => m.ConsentModule)
      },
      {
        path: 'cookie-policies',
        canActivate: [AuthGuard],
        component: CookiePoliciesComponent
      },
      {
        path: 'offline',
        component: OfflineComponent
      },
    ]
  },
  {
    path: 'logged-out',
    component: LoggedOutComponent
  },
  {
    path: 'not-validated',
    canActivate: [AuthGuard],
    component: NotValidatedComponent
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  { path: '**', redirectTo: '/not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // enableTracing: true, /* For debugging purposes */
    relativeLinkResolution: 'legacy',
    onSameUrlNavigation: 'ignore'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
