import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppStateService } from 'src/app/shared/state/app-state/app-state.service';
import { NavigationItem } from './navigation-item.interface';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationComponent {
  @Output() menuItemPressed = new EventEmitter<void>();
  @Input() navigationItems: NavigationItem[];

  constructor(private router: Router, private appState: AppStateService) { }

  navigate(navigationItem: NavigationItem) {
    this.menuItemPressed.emit();
    this.appState.disableScroll.value = false;
    if(navigationItem.isExternalRouterLink)
      window.open(navigationItem.routerLink)
    else
      this.router.navigate([navigationItem.routerLink])
  }
}
