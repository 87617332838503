import { Inject, Injectable } from '@angular/core';
import { StorageService } from 'src/app/libraries/seges-web-utils/state-item/storage.interface';
import { WINDOW_REF } from 'src/config/consts/window-ref.token';
import { WindowRef } from 'src/config/model/window-ref.interface';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService implements StorageService {

  constructor(@Inject(WINDOW_REF) private window: WindowRef) { }

  getItem(key: string): any {
    const strValue = this.window.sessionStorage.getItem(key);
    return !!strValue && strValue !== 'undefined' ? JSON.parse(strValue) : null;
  }

  setItem(key: string, val: any): any {
    return this.window.sessionStorage.setItem(key, JSON.stringify(val));
  }
}
