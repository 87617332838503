import { Inject, Injectable } from '@angular/core';
import { WINDOW_REF } from 'src/config/consts/window-ref.token';
import { WindowRef } from 'src/config/model/window-ref.interface';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(@Inject(WINDOW_REF) private window: WindowRef) { }

  getItem(key: string): any {
    return JSON.parse(this.window.localStorage.getItem(key));
  }

  setItem(key: string, val: any): any {
    return this.window.localStorage.setItem(key, JSON.stringify(val));
  }
}
