import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppStateService } from 'src/app/shared/state/app-state/app-state.service';
import { AuthorizationService } from '../../authorization/authorization.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  
  constructor(
    private authService: AuthorizationService,
    private appState: AppStateService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      headers: this.getHeaders(),
    });
    
    return next.handle(request);
  }

  private getHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
      Authorization: `${this.authService.getAuthorizationHeaderValue()}`,
      CorrelationId: `${this.correlationId}`,
      YearInfo: `${this.appState.selectedYearScope.value?.shortKey}`,
    });
    return headers;
  }

  get correlationId(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}
