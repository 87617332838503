import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppUser } from 'src/app/shared/models/misc/app-user.interface';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserInfoComponent {
  @Input() user: AppUser;
}
