import { NgModule } from '@angular/core';
import { AutoSelectDirective } from './directives/auto-select/auto-select.directive';
import { E2eIdDirective } from './directives/e2e/e2e-id.directive';
import { DisableControlDirective } from './directives/forms/misc/disable-control.directive';
import { RegisterFormDirective } from './directives/forms/register-form/register-form.directive';
import { ValidationRulesDirective } from './directives/forms/validation-rules/validation-rules.directive';
import { AnimalNumberValidator } from './directives/forms/validators/animal-number-validator.directive';
import { InvalidNumbersValidator } from './directives/forms/validators/invalid-numbers-validator.directive';
import { MaxDateValidator } from './directives/forms/validators/max-date-validator.directive';
import { MaxValueValidator } from './directives/forms/validators/max-value-validator.directive';
import { MinDateValidator } from './directives/forms/validators/min-date-validator.directive';
import { MinValueValidator } from './directives/forms/validators/min-value-validator.directive';
import { MarkAsNewDirective } from './directives/mark-as-new/mark-as-new.directive';
import { NonSelectableDirective } from './directives/non-selectable/non-selectable.directive';


@NgModule({
  declarations: [
    E2eIdDirective,
    MinDateValidator,
    MaxDateValidator,
    MaxValueValidator,
    MinValueValidator,
    MarkAsNewDirective,
    AutoSelectDirective,
    RegisterFormDirective,
    AnimalNumberValidator,
    NonSelectableDirective,
    InvalidNumbersValidator,
    ValidationRulesDirective,
    DisableControlDirective,
  ],
  exports: [
    E2eIdDirective,
    MinDateValidator,
    MaxDateValidator,
    MaxValueValidator,
    MinValueValidator,
    MarkAsNewDirective,
    AutoSelectDirective,
    RegisterFormDirective,
    AnimalNumberValidator,
    NonSelectableDirective,
    InvalidNumbersValidator,
    ValidationRulesDirective,
    DisableControlDirective,
  ]
})

export class LsDirectivesModule { }
