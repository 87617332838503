// tslint:disable:directive-selector
import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { MatRipple } from '@angular/material/core';

@Directive({
  selector: 'base-btn',
  providers: [MatRipple]
})
export class BaseBtnDirective {
  
  @Input() type = 'button';
  
  @HostBinding('type')
  get typeBinding() {
    return this.type
  }

  constructor(private ripple: MatRipple) { }

  @HostListener('mousedown', ['$event']) onMouseDown(event: MouseEvent): void {
    this.ripple.launch(event.x, event.y);
  }
}
