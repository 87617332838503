import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * takeUntilDestroy operator, to be used with @EmitOnDestroy decorator
 * Important: Should always be the last operator in your pipe
 * @param prop this pointer to the component you decorated with @EmitOnDestroy
 * @returns Observable of T
 */
export function takeUntilDestroy<T>(prop: unknown): (source: Observable<T>) => Observable<T> {
  return function untilDestroyFn<T>(source: Observable<T>): Observable<T> {
    if (!prop || !prop['unsubscribe']) {
      throw new Error('Remember to use the @EmitOnDestroy() decorator before takeUntilDestroy operator');
    }
    return source.pipe(takeUntil(prop['unsubscribe'] as Observable<void>));
  };
}
