import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { map, take } from "rxjs/operators";
import { StateItem } from "src/app/libraries/seges-web-utils/state-item/state-item.class";
import { YearScope } from "../../components/header/year-scope-picker/year-scope.interface";
import { yearScopes } from "../../components/header/year-scope-picker/year-scope.options";
import { Language } from "../../components/language-picker/language.interface";
import { appLanguages } from "../../components/language-picker/language.options";
import { BusinessEntity } from "../../models/dto/business-entity.interface";
import { UserDelegation } from "../../models/dto/user-delegation.interface";
import { AppUser } from "../../models/misc/app-user.interface";
import { NotificationService } from "../../modules/notification/notification.service";
import { BusinessEntityRepo } from "../../repos/business-entity/business-entity.repo";
import { DelegationRepo } from "../../repos/delegation/delegation.repo";
import { LocalStorageService } from "../../services/storage/local-storage/local-storage.service";

@Injectable({
  providedIn: "root",
})
export class AppStateService {
  loading = new StateItem<boolean>(false);
  userLoggedIn = new StateItem<boolean>(false);
  disableScroll = new StateItem<boolean>(false);
  userDelegations = new StateItem<UserDelegation[]>([]);
  userState = new StateItem<AppUser>(null, "USER_STATE", this.localStorage);
  delegationToken = new StateItem<string>(
    null,
    "DELEGATION_TOKEN",
    this.localStorage
  );
  businessEntity = new StateItem<BusinessEntity>(
    null,
    "BUSINESS_ENTITY",
    this.localStorage
  );
  selectedBusiness = new StateItem<BusinessEntity>(
    null,
    "SELECTED_BUSINESS",
    this.localStorage
  );
  delegatedUserState = new StateItem<UserDelegation>(
    null,
    "DELEGATED_USER_STATE",
    this.localStorage
  );
  selectedLanguage = new StateItem<Language>(
    appLanguages[0],
    "SELECTED_LANGUAGE",
    this.localStorage
  );
  selectedYearScope = new StateItem<YearScope>(
    yearScopes[0],
    "SELECTED_YEAR_SCOPE",
    this.localStorage
  );

  constructor(
    private delegationRepo: DelegationRepo,
    private businessEntityRepo: BusinessEntityRepo,
    private localStorage: LocalStorageService,
    private notification: NotificationService,
    private translate: TranslateService,
    private router: Router
  ) { }

  async initialize(): Promise<any> {
    const loggedIn = this.userLoggedIn.value;
    const cvr =
      this.delegatedUserState.value?.cvrNumber ??
      this.userState.value?.cvr ??
      this.selectedBusiness.value?.cvrNumber?.toString();
    let delegations: UserDelegation[];
    if (loggedIn) {
      try {
        delegations = await this.delegationRepo.get().toPromise();
      } catch (err) {
        console.error("Coudnt get delegations in initialize", err);
        this.goToNotValidatedPage("validation.noBusinessOrDelegations");
      }
    }

    if (loggedIn && cvr && delegations?.length === 0) {
      return this.businessEntityRepo
        .get(cvr)
        .pipe(
          map((business) => {
            if (!business) {
              this.goToNotValidatedPage("validation.businessNotFound");
            }

            this.delegationRepo
              .get()
              .pipe(take(1))
              .subscribe((delegations) => {
                this.userDelegations.value = delegations;
              });

            this.checkAndSetBusiness(business);
            return !!business;
          })
        )
        .toPromise()
        .catch(() => {
          this.goToNotValidatedPage("validation.businessNotFound");
        });
    } else if (loggedIn && cvr) {
      return this.businessEntityRepo
        .get(cvr)
        .pipe(
          map((business) => {
            if (!business) {
              this.goToNotValidatedPage("validation.businessNotFound");
            }

            this.delegationRepo
              .get()
              .pipe(take(1))
              .subscribe((delegations) => {
                this.userDelegations.value = delegations;
              });

            this.checkAndSetBusiness(business);
            return !!business;
          })
        )
        .toPromise()
        .catch(() => {
          this.goToNotValidatedPage("validation.businessNotFound");
        });
    }
    else if (loggedIn) {
      return this.delegationRepo
        .get()
        .pipe(
          map((delegations) => {
            if (!this.userState.value.isAdmin && !delegations?.length) {
              this.goToNotValidatedPage("validation.noBusinessOrDelegations");
            }
            this.userDelegations.value = delegations;
            return this.userState.value.isAdmin || delegations?.length;
          })
        )
        .toPromise()
        .catch(() => {
          this.goToNotValidatedPage("validation.noBusinessOrDelegations");
        });
    }
  }

  

  reset() {
    this.delegatedUserState.value = null;
    this.selectedBusiness.value = null;
    this.delegationToken.value = null;
    this.businessEntity.value = null;
    this.userLoggedIn.value = false;
    this.userDelegations.value = [];
    this.userState.value = null;
  }

  resetDelegation() {
    this.userDelegations.value = [];
    this.delegationToken.value = null;
    this.delegatedUserState.value = null;
  }

  checkAndSetBusiness(business: BusinessEntity) {
    if(business.yearScopeKey !== this.selectedYearScope.value.shortKey){
      const yearScope = yearScopes.find(x => x.shortKey === business.yearScopeKey);
      this.selectedYearScope.value = yearScope ? yearScope : yearScopes[0];
    }
    this.businessEntity.value = business;
    this.selectedBusiness.value = business;
  }

  private goToNotValidatedPage(errMessage: string) {
    this.notification.showError(this.translate.instant(errMessage));
    this.router.navigate(["/not-validated"]);
  }
}
