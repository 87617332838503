import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent {
  message: string;

  constructor(private snackBarRef: MatSnackBarRef<SuccessComponent>, @Inject(MAT_SNACK_BAR_DATA) data: any) {
    this.message = data.message;
  }

  close(): void {
    this.snackBarRef.dismiss();
  }
}
