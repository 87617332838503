import { User } from "oidc-client";

export const farmerUserTypes = ["Landmand", "Farmer"];
export function getUserTypeClaim(u: User): string {
  //TODO 2021-11-29 MAC User types currently disabled
  //return u?.profile?.['http://dli.ws.dlbr.dk/ws/2008/04/property/claims/usertypes'];
  return "Farmer";
}
export function getDisplayNameClaim(u: User): string {
  return u?.profile?.["name"];
}
export function getCvrNumberClaim(u: User): string {
  return u?.profile?.["cvr_number_verified"];
}
