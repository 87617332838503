import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DEFAULT_CULTURE } from '../../tokens/default-culture.token';


@NgModule({
  imports: [TranslateModule.forChild(), HttpClientModule],
  exports: [TranslateModule],
  providers: [{ provide: DEFAULT_CULTURE, useValue: 'da' }]
})
export class AppTranslateModule {
  constructor(translate: TranslateService) {
    translate.setDefaultLang('da');
  }
}
