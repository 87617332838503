import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-center-content',
  templateUrl: './center-content.component.html',
  styleUrls: ['./center-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CenterContentComponent {
  @Input() maxWidth: string;
  @Input() minWidth: string;
}
