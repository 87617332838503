export enum EmissionTag {
  FertilizerProduction = 'FertilizerProduction',
  EnergyAndMachinework = 'EnergyAndMachinework',
  CarbonBalanceField = 'CarbonBalanceField',
  ImportToBusiness = 'ImportToBusiness',
  ImportPoultry = 'ImportPoultry',
  ImportCattle = 'ImportCattle',
  ImportPigs = 'ImportPigs',
  FeedPoultry = 'Feed.Poultry',
  FeedCattle = 'FeedCattle',
  FeedPig = 'FeedPig',
  Bedding = 'Bedding',
}
