import { BehaviorSubject, Observable } from 'rxjs';
import { IStateItem } from './state-item.interface';
import { StorageService } from './storage.interface';

export class StateItem<T> implements IStateItem<T> {
  private itemSubject: BehaviorSubject<T>;
  private persistValue: boolean;
  private item: T;

  value$: Observable<T>;

  constructor(defaultValue: any, public readonly objectKey?: string, private storage?: StorageService) {
    this.persistValue = !!objectKey && !!storage;
    let persistedValue = null;
    if (storage && objectKey) {
      const item = storage.getItem(objectKey);
      if (item) {
        try {
          persistedValue = JSON.parse(item) as T;
        } catch {
          persistedValue = null;
        }
      }
    }
    this.item = persistedValue ?? defaultValue;
    this.itemSubject = new BehaviorSubject<T>(this.item);
    this.value$ = this.itemSubject.asObservable();
  }

  set value(to: T) {
    this.item = to;
    this.itemSubject.next(to);
    if (this.persistValue && this.objectKey && this.storage) {
      this.storage.setItem(this.objectKey, JSON.stringify(to));
    }
  }

  get value(): T {
    return this.item;
  }
}
