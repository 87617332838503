import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationService } from 'src/app/shared/services/navigation/navigation.service';
import { AppStateService } from 'src/app/shared/state/app-state/app-state.service';

@Component({
  selector: 'app-app-shell',
  templateUrl: './app-shell.component.html',
  styleUrls: ['./app-shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppShellComponent {
  navItems = this.navigationService.getNavigationItems();
  loading$ = this.appState.loading.value$;
  disableScroll$ = this.appState.disableScroll.value$;

  constructor(private appState: AppStateService, private navigationService: NavigationService) { }
}
