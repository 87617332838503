import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppStateService } from '../../state/app-state/app-state.service';

@Injectable({
  providedIn: 'root'
})
export class UserCvrValidatedGuard implements CanActivate {

  constructor(private appState: AppStateService, private router: Router) { }

  canActivate(): Observable<boolean> {
    return combineLatest([
      this.appState.userState.value$,
      this.appState.delegatedUserState.value$,
      this.appState.userDelegations.value$,
      this.appState.selectedBusiness.value$]).pipe(
        map(([user, delegatedUser, delegations, selectedBusiness]) => {
          const cvr = delegatedUser?.cvrNumber ?? user?.cvr ?? selectedBusiness?.cvrNumber;
          if (!cvr) {
            if (!delegations?.length && !user?.isAdmin) {
              this.router.navigate(['not-validated']);
            } else {
              this.router.navigate(['app/delegation']);
            }
          }
          return !!cvr;
        })
      );
  }
}
