<div class="footer-div">
  <img class="logo" src="assets/images/SEGES_innovation_logo_green.svg">
  <div class="contact">
    <p>{{ 'common.needHelp' | translate }}</p>
    <div class="contact-info">
      <mat-icon style="color: white">phone</mat-icon>
      <a href="callto:70155015">+45 7015 5015</a>
    </div>
    <div class="contact-info">
      <mat-icon style="color: white">email</mat-icon>
      <a href="mailto:kundecenter@seges.dk">kundecenter@seges.dk</a>
    </div>
    <div class="cookie-link"><a  routerLink="/app/cookie-policies">Cookiepolitik</a></div>
  </div>
  <img class="logo sponsor" src="assets/images/sponsor-logo.png">
</div>
