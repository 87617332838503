<div class="container">
  <app-year-scope-picker></app-year-scope-picker>
    <div class="text-section">
        <h1>
            Bedriftens Klimaaftryk
        </h1>
        <p>
            Her får du overblik over klimaaftrykket for den samlede produktion på bedriften.
        </p>
    </div>
    <div class="box-section"  *ngIf="totalScenarioCo2e">
        <div class="box-container">
            <div class="box" routerLink="/app/data" [fragment]="'field'">
                <div class="arrow"></div>
                <h3>
                    Standard og overført
                </h3>
                <p class="total">
                    {{ emissions.value.businessEmissionDto.standardCO2e | massConversion | numberToString: 1 : 1 | nullToString }} ton CO<sub>2</sub>e
                </p>
                <p class="link">
                    Se datagrundlag
                </p>
                <div class="footer">
                    <p>
                        Aftrykket er beregnet på baggrund af data fra gødningsregnskabet {{(yearScope$ | async)?.shortKey}} og standardværdier.
                    </p>
                </div>
            </div>
        </div>
        <div class="box-container">
            <div class="box" routerLink="/app/data" [fragment]="'field'">
                <div class="arrow"></div>
                <h3>
                    Bedriftens tal
                </h3>
                <p class="total">
                    {{ emissions.value.businessEmissionDto.adjustedCO2e | massConversion | numberToString: 1 : 1 | nullToString }} ton CO<sub>2</sub>e
                </p>
                <p class="link">
                    Præciser datagrundlag
                </p>
                <div class="footer">
                    <p>
                        Tilpas bedriftsspecifikke detaljer om f.eks dræning, klimateknologier og foder, så du får et mere præcist aftryk.
                    </p>
                </div>
            </div>
        </div>
        <div class="box-container">
            <div class="box" routerLink="/app/climate-footprint">
                <h3>
                    Fremtidsscenarie
                </h3>
                <p class="total">
                    {{ totalScenarioCo2e | massConversion | numberToString: 1 : 1 | nullToString }} ton CO<sub>2</sub>e
                </p>
                <p class="link">
                    Beregn effekt af tiltag
                </p>
                <div class="footer">
                    <p>
                        Beregn effekten af klimatiltag på din bedrift og vælg hvordan du vil nedbringe klimaudledningen.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <h2 *ngIf="totalScenarioCo2e" class="graph-header">
        Bedriftens kilder til udledning af klimagasser
    </h2>
    <div class="graph-section"  *ngIf="totalScenarioCo2e">
        <div class="graph-wrap">
            <canvas baseChart 
                [datasets]="barChartData"
                [labels]="barChartLabels"
                [options]="barChartOptions"
                [plugins]="barChartPlugins"
                [legend]="barChartLegend"
                [type]="barChartType">
            </canvas>
        </div>
    </div>
</div>