export interface PublishEventLog {
  message: string;
  type: EventLogType;
}

export interface EventLogRequest {
  message: string;
  type: string;
  cvr: string;
}

export enum EventLogType {
  PrintResult = 'PrintResult',
  UserLogIn = 'UserLogIn'
}
