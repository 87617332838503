<mat-toolbar class="header">
  <div class="menu-and-title">
    <button class="btn" id="menu-id" mat-button (click)="menuBtnPressed()">
      <mat-icon>menu</mat-icon>
      menu
    </button>
    <a (click)="goHome()" id="app-title" href="/"><img  src="assets/images/ESGreenTool_logo_RGB_linje.svg"></a>
    <!-- <h1 (click)="goHome()" id="app-title">      
      <span class="bold-part"><span style="color: #01511E;">ESGreen</span> <span style="color: #71AF8A;">Tool</span></span>
    </h1> -->
  </div>

 
  <div class="user-settings">
    <app-user-info [user]="combinedUser$ | async"></app-user-info>
    <app-settings [user]="user$ | async"></app-settings>
  </div>

</mat-toolbar>