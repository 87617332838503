import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { ConsentRepo } from "src/app/views/consent/consent.repo";
import { AppStateService } from "../../state/app-state/app-state.service";

@Injectable({
    providedIn: "root",
})
export class UserConsentedGuard implements CanActivate {
    constructor(
        private appState: AppStateService,
        private consentRepo: ConsentRepo,
        private router: Router
    ) { }
    canActivate(): Observable<boolean> {
        const cvrStr = this.appState.delegatedUserState.value?.cvrNumber ?? this.appState.userState.value?.cvr ?? this.appState.selectedBusiness.value?.cvrNumber.toString();
        let cvr = parseInt(cvrStr);
        if (this.appState.userState?.value?.isAdmin) {
            if (!isNaN(this.appState.businessEntity?.value?.cvrNumber)) {
                cvr = this.appState.businessEntity?.value?.cvrNumber;
            }
            return of(true);
        }
        if(cvr){
          return this.consentRepo
              .hasConsented(cvr).pipe(
                  mergeMap(consented =>
                      this.consentRepo.getConsentUrlWithReturnUrl(cvr).pipe(map(url => {
                          if (!consented)
                              window.location.href = url;
                          return true;
                      }))
                  )
                );
        } else {
          return of(true);
        }
    }
}
