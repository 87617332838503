import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserDelegation } from '../../models/dto/user-delegation.interface';
import { HttpClientService } from '../../services/http-client/http-client.service';
import { Repo } from '../repo.interface';

@Injectable({
  providedIn: 'root'
})
export class DelegationRepo implements Repo<UserDelegation[]> {
  controllerName = 'UserDelegation';

  constructor(private http: HttpClientService) { }

  get(): Observable<UserDelegation[]> {
    return this.http.get(`${this.controllerName}/GetUserDelegations`).pipe(
      map((res: { userDelegations: UserDelegation[] }) => res.userDelegations));
  }

  getAccessToken(adLogonName: string): Observable<string> {
    return this.http.post(`${this.controllerName}/getUserDelegationToken`, { adLogonName }).pipe(
      map((res: { delegationAccessToken: string }) => res.delegationAccessToken));
  }
}
