import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NotificationService } from './notification.service';
import { ErrorComponent } from './error/error.component';

import { SuccessComponent } from './success/success.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        MatSnackBarModule,
        MatIconModule,
        MatButtonModule,
        TranslateModule
    ],
    providers: [NotificationService],
    declarations: [ErrorComponent, SuccessComponent]
})
export class NotificationModule { }
