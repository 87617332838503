import { Dictionary } from 'src/app/shared/models/misc/dictionary.interface';
import { EmissionTag } from './emission-tag.enum';
import { EpicTag } from './epic-tag.enum';

export const EmissionLinksMap: Dictionary<EpicTag> = {
  [EmissionTag.EnergyAndMachinework]: EpicTag.BeddingAndEnergy,
  [EmissionTag.FertilizerProduction]: EpicTag.Field,
  [EmissionTag.Bedding]: EpicTag.BeddingAndEnergy,
  [EmissionTag.ImportPoultry]: EpicTag.Poultry,
  [EmissionTag.FeedPoultry]: EpicTag.Poultry,
  [EmissionTag.ImportCattle]: EpicTag.Cattle,
  [EmissionTag.FeedCattle]: EpicTag.Cattle,
  [EmissionTag.ImportPigs]: EpicTag.Pig,
  [EmissionTag.FeedPig]: EpicTag.Pig,
};
