import { Inject, Injectable } from '@angular/core';
import includes from 'lodash-es/includes';
import { User, UserManager } from 'oidc-client';
import { OIDC_CONFIG } from 'src/config/consts/oidc-config.token';
import { WINDOW_REF } from 'src/config/consts/window-ref.token';
import { WindowRef } from 'src/config/model/window-ref.interface';
import { AppUser } from '../../models/misc/app-user.interface';
import { AppStateService } from '../../state/app-state/app-state.service';
import { farmerUserTypes, getCvrNumberClaim, getDisplayNameClaim, getUserTypeClaim } from './claims.const';
import { UserRoles } from './user-roles.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
private tokenType: string;
private token: string;

  constructor(
    private appState: AppStateService,
    // private httpInterceptorService: HttpInterceptorService,
    @Inject(WINDOW_REF) private windowRef: WindowRef,
    @Inject(OIDC_CONFIG) private oidcManager: UserManager
  ) { }

  async initialize(): Promise<any> {
    this.initEventListeners();
    await this.signInRedirectCallback();
    return this.loadUser();
  }

  /**
   * Load the logged in user.
   * If no logged in user, the client is sent to the authorization site.
   */
  async loadUser(): Promise<User> {
    try {
      const user = await this.oidcManager.getUser();
      if (user && !user.expired) {
        this.token = user.access_token;
        this.tokenType = user.token_type;
        this.appState.userState.value = this.getAppUser(user);
        this.appState.userLoggedIn.value = true;
        if(this.appState.delegatedUserState.value){
          // For now, the backend returns the users own access_token instead of the delegator token, thus we set delegationToken to users own token.
          this.appState.delegationToken.value = this.token;
        }
      }
      return Promise.resolve(user);
    } catch (err) {
      this.startSigninMainWindow();
      return Promise.resolve(err);
    }
  }

  /**
   * Setup which user session events to handle.
   */
  initEventListeners(): void {
    this.oidcManager.events.addUserUnloaded(() => this.userUnloadedHandler());
    this.oidcManager.events.addUserLoaded(async () => await this.userLoadedHandler());
    this.oidcManager.events.addAccessTokenExpired(() => this.sessionExpiredHandler());
    this.oidcManager.events.addSilentRenewError(() => this.silentRenewErrorHandler());
  }
  private async userLoadedHandler(): Promise<void> {
    // Shoehorn the reloaded user into app state so the new tokens will be used
    await this.loadUser();
  }

  /**
   * Redirects to the authorization page.
   */
  async startSigninMainWindow() {
    // This method is called when user is not set (logged in).
    // First call '/' (without #) -> causes redirect to fed gate login page
    // When returning from redirect url contains '#id_token'
    // User is still not logged in because 'userLoadedHandler' is not called yet
    // Prevent signinRedirect being called and wait for  'userLoadedHandler'.
    // 'userLoadedHandler' will redirect to '/' when user is set.
    if (!this.windowRef.location.hash) {
      try {
        const returnUrl = this.windowRef.location.href.replace('logged-out', '');
        await this.oidcManager.signinRedirect({
          state: { returnUrl }
        });
      } catch (err) {
        console.error(err);
      }
    }
  }

  /**
   * Handles the JWT token from the authentication server.
   */
  async signInRedirectCallback(): Promise<void> {
    const token = this.windowRef.location.hash;
    const isOidcFragmentCodeCallback: boolean = token.indexOf('code') > -1;
    if (isOidcFragmentCodeCallback) {
      const user = await this.oidcManager.signinRedirectCallback(token);
      this.updateLocationHash('');
      if (!user) {
        console.warn('No sign-in request pending');
      } else {
        this.windowRef.location.href = user.state?.returnUrl;
      }
    }
  }

  async startSignoutMainWindow() {
    try {
      const user = await this.oidcManager.getUser();
      await this.oidcManager.signoutRedirect({ id_token_hint: user?.id_token });
    } catch (err) {
      console.error(err);
    }
  }

  getAuthorizationHeaderValue(): string {
    return `${this.tokenType} ${this.token}`;
  }

  resetDelegationAccessToken() {
    this.token = this.appState.userState.value.token;
    this.appState.delegationToken.value = null;
  }

  setDelegationAccessToken(accessToken: string) {
    this.token = accessToken;
    this.appState.delegationToken.value = accessToken;
  }

  signOut(): void {
    this.startSignoutMainWindow();
  }

  private debugConsoleLog(message: string): void {
  }

  private async sessionExpiredHandler() {
    this.debugConsoleLog('Session expiring...should auto-refresh');
  }

  private silentRenewErrorHandler(): void {
    this.debugConsoleLog('Error during refresh...signing out');
    this.oidcManager.signoutRedirect();
  }

  private userUnloadedHandler(): void {
    this.oidcManager.clearStaleState();
    this.appState.reset();
  }

  private updateLocationHash(newVal: string) {
    if (this.windowRef.history.pushState) {
      this.windowRef.history.pushState(null, null, newVal);
    } else {
      this.windowRef.location.hash = newVal;
    }
  }

  private getAppUser(user: User): AppUser {
    this.debugConsoleLog('Getting user');
    return {
      userName: user?.profile?.sub,
      displayName: getDisplayNameClaim(user),
      isFarmer: !!user ? includes(farmerUserTypes, getUserTypeClaim(user)) : false,
      isTestUser: !!user ? includes(user?.profile?.role, UserRoles.TestUser) : false,
      isAdmin: !!user ? includes(user?.profile?.role, UserRoles.Admin) : false,
      cvr: getCvrNumberClaim(user),
      token: user?.access_token
    };
  }
}
