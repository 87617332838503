import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InputRule } from '../../models/dto/input-rule.interface';
import { HttpClientService } from '../../services/http-client/http-client.service';
import { Repo } from '../repo.interface';

@Injectable({
  providedIn: 'root'
})
export class InputRulesRepo implements Repo<InputRule[]> {
  controllerName = 'InputRule';

  constructor(private httpClient: HttpClientService) { }

  get(): Observable<InputRule[]> {
    return this.httpClient.get(`${this.controllerName}/GetInputRules`).pipe(
      map((res: any) => res.inputRules));
  }
}
