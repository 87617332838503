import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  errorMessage: string;

  constructor(private snackBarRef: MatSnackBarRef<ErrorComponent>, @Inject(MAT_SNACK_BAR_DATA) data: any) {
    this.errorMessage = data.errorMessage;
  }

  ngOnInit() {
  }

  close(): void {
    this.snackBarRef.dismiss();
  }
}
