import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { ClimateFootprint } from '../../models/dto/climate-footprint.interface';
import { AppUser } from '../../models/misc/app-user.interface';
import { EventLogRequest, PublishEventLog } from '../../models/requests/event-log-request.interface';
import { HttpClientService } from '../../services/http-client/http-client.service';
import { AppStateService } from '../../state/app-state/app-state.service';
import { DataStateService } from '../../state/data-state/data-state.service';
import { Repo } from '../repo.interface';

@Injectable({
  providedIn: 'root'
})
export class EventLogRepo implements Repo<ClimateFootprint> {
  controllerName = 'EventLog';
  user$: Observable<AppUser>;

  constructor(private http: HttpClientService,
    private appState: AppStateService,
    private dataState: DataStateService) { }

  publishEventLog(request: PublishEventLog) {

    this.user$ = this.appState.userState.value$;
    combineLatest([this.user$, this.appState.delegatedUserState.value$, this.appState.selectedBusiness.value$])
      .pipe(take(1))
      .subscribe(([user, delegatedUser, selectedBusiness]) => {
        const activeCvr = user.isAdmin && selectedBusiness
          ? selectedBusiness.cvrNumber.toString()
          : delegatedUser ? delegatedUser.cvrNumber : user.cvr;

        if (activeCvr) {
          const body: EventLogRequest = {
            message: request.message,
            type: request.type,
            cvr: activeCvr
          }

          return this.http.post(`${this.controllerName}/Publish`, body).toPromise();
        }
      });
  }
}
