// tslint:disable:directive-selector
import { Directive, HostBinding } from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { BaseBtnDirective } from '../base/base-btn.directive';

@Directive({
  selector: '[lf-btn]',
  providers: [MatRipple]
})
export class LfBtnDirective extends BaseBtnDirective {

  @HostBinding('class') elementClass = 'base-btn lf-btn';

  constructor(ripple: MatRipple) {
    super(ripple);
  }
}
