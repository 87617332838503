import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { BaseBtnDirective } from './buttons/base/base-btn.directive';
import { LfBtnDirective } from './buttons/lf-btn/lf-btn.directive';
import { LfIconBtnDirective } from './buttons/lf-icon-btn/lf-icon-btn.directive';
import { LfNavBtnDirective } from './buttons/lf-nav-btn/lf-nav-btn.directive';
import { LfOutlineBtnDirective } from './buttons/lf-outline-btn/lf-outline-btn.directive';
import { LfSquareIconBtnDirective } from './buttons/lf-square-icon-btn/lf-square-icon-btn.directive';
import { LfTransBtnDirective } from './buttons/lf-trans-btn/lf-trans-btn.directive';


@NgModule({
  declarations: [
    LfBtnDirective,
    BaseBtnDirective,
    LfNavBtnDirective,
    LfIconBtnDirective,
    LfTransBtnDirective,
    LfOutlineBtnDirective,
    LfSquareIconBtnDirective
  ],
  imports: [
    CommonModule,
    MatRippleModule
  ],
  exports: [
    LfBtnDirective,
    LfNavBtnDirective,
    LfIconBtnDirective,
    LfTransBtnDirective,
    LfOutlineBtnDirective,
    LfSquareIconBtnDirective
  ]
})
export class LFButtonModule { }
