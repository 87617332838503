import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BusinessEmission } from '../../models/dto/business-emission.interface';
import { EmissionDetailsResponse } from '../../models/responses/emission-details-response.interface';
import { EmissionOverviewResponse } from '../../models/responses/emission-overview-response.interface';
import { HttpClientService } from '../../services/http-client/http-client.service';
import { Repo } from '../repo.interface';

@Injectable({
  providedIn: 'root'
})
export class EmissionRepo implements Repo<any> {
  controllerName = 'Emission';

  constructor(private httpClient: HttpClientService) { }

  getEmission(businessEntityId: number, climateFootprintId: number): Observable<BusinessEmission> {
    const query = `?businessEntityId=${businessEntityId}&climateFootPrintId=${climateFootprintId}`;
    return this.httpClient.get(`${this.controllerName}/GetEmission${query}`).pipe(
      map((res: any) => res.businessEmission));
  }

  getEmissionsOverview(businessEntityId: number): Observable<EmissionOverviewResponse> {
    const query = `?businessEntityId=${businessEntityId}`;
    return this.httpClient.get(`${this.controllerName}/GetEmissionsOverview${query}`).pipe(
      map((res: any) => res));
  }

  getEmissionDetails(businessEntityId: number, climateFootprintId: number): Observable<EmissionDetailsResponse> {
    const query = `?businessEntityId=${businessEntityId}&climateFootPrintId=${climateFootprintId}`;
    return this.httpClient.get(`${this.controllerName}/GetEmissionDetails${query}`).pipe(
      map((res: EmissionDetailsResponse) => res));
  }
}
