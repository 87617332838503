import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'massConversion' })
export class MassConversionPipe implements PipeTransform {
  transform(value: number, conversion: string = 'kg-t'): number {

    if (value === undefined || value === null) {
      return null;
    }

    let factor: number;
    switch (conversion as string) {
      case 't-kg':
        factor = 1000;
        break;
      case 'kg-t':
      default:
        factor = 1 / 1000;
        break;
    }

    return value * factor;
  }
}
