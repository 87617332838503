import { ErrorHandler, Inject, Injectable } from "@angular/core";
import { LogService } from "src/app/libraries/angular-utils/Services/log/log.service";
import { APP_CONFIG } from "src/config/consts/app-config.token";
import { AppConfig } from "src/config/model/app-config.interface";

@Injectable()
export class ErrorHandlerService implements ErrorHandler {

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private logService: LogService
  ) { }

  handleError(error: any): void {
    if (this.config.config.production) {
      this.logService.logError(JSON.stringify(error));
    } else {
      console.log(error);
    }
  }
}
