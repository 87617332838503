import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'nullToString' })
export class NullToStringPipe implements PipeTransform {
  transform(value: string, nullvalue: string = '-'): string {
    if (value === undefined || value === null || String(value).length === 0) {
      return nullvalue ? nullvalue : ' ';
    }

    return value;
  }
}
