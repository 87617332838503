import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppUser } from '../../models/misc/app-user.interface';
import { NavigationService } from '../../services/navigation/navigation.service';
import { AppStateService } from '../../state/app-state/app-state.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() menuPressed = new EventEmitter<void>();
  combinedUser$: Observable<AppUser>;
  user$: Observable<AppUser>;
  navItems = this.navigationService.getNavigationItems();

  constructor(private appState: AppStateService, private router: Router, private navigationService: NavigationService) { }

  get isHome(): boolean {
    return this.router.url?.includes(this.navItems[0]?.routerLink);
  }

  ngOnInit() {
    this.user$ = this.appState.userState.value$;
    this.combinedUser$ = combineLatest([this.user$, this.appState.delegatedUserState.value$, this.appState.selectedBusiness.value$]).pipe(
      map(([user, delegatedUser, selectedBusiness]) => {
        if(user.isAdmin && selectedBusiness) {
          let adminSelectedBusiness = {
            cvr: selectedBusiness.cvrNumber.toString(),
            displayName: selectedBusiness.name
          } as AppUser
          return adminSelectedBusiness
        } else {
          return !!delegatedUser ? {
            cvr: delegatedUser.cvrNumber,
            displayName: delegatedUser.name,
            userName: delegatedUser.adLogonName
          } as AppUser : user;
        }

      })
    );
  }

  goHome() {
    if (!this.isHome) {
      this.router.navigate([this.navItems[0].routerLink]);
    }
  }

  menuBtnPressed() {
    this.menuPressed.emit();
  }
}
