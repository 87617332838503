import { InjectionToken } from '@angular/core';

export class AppInjectionToken extends InjectionToken<any> {
  key: string;

  constructor(key: string) {
    super(key);
    this.key = key;
  }

  toString(): string {
    return this.key;
  }
}
