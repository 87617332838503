import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';


@Component({
  selector: 'app-cookie-policies',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiePoliciesComponent implements AfterViewInit {
  @ViewChild('cookieScript', { static: true }) public cookieScript?: ElementRef;
  //Property to define which language the cookie policy needs to use. Use shortKey like DA or EN
  language: string = 'da';

  constructor(private renderer2: Renderer2) {}

  public ngAfterViewInit() {
    const s = this.renderer2.createElement('script');
    this.renderer2.setAttribute(s, 'id', 'CookiePolicy');
    this.renderer2.setAttribute(s, 'type', 'text/javascript');
    this.renderer2.setAttribute(s, 'src', 'https://policy.app.cookieinformation.com/cid.js');
    this.renderer2.setAttribute(s, 'data-culture', this.language);

    if (this.cookieScript && this.cookieScript.nativeElement) {
      this.renderer2.appendChild(this.cookieScript.nativeElement, s);
    }
  }
}

