import { Inject, Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { appLanguages } from '../../components/language-picker/language.options';
import { AppStateService } from '../../state/app-state/app-state.service';
import { DEFAULT_CULTURE } from '../../tokens/default-culture.token';
import { LanguageConstants } from './language.consts';
import { Language } from './language.interface';


@Injectable({
  providedIn: 'root'
})
export class LanguageService implements OnDestroy {
  private subs = new Subscription();
  public currentLanguage: Language;
  public onLangChange = this.translate.onLangChange;

  constructor(
    private appState: AppStateService,
    private translate: TranslateService,
    @Inject(DEFAULT_CULTURE) public defaultCulture) {
    this.init();
  }

  public init(): Promise<void> {
    this.setLanguage(this.appState.selectedLanguage.value ? this.appState.selectedLanguage.value : this.defaultCulture);

    moment.locale(this.currentLanguage.shortKey);

    this.subs.add(
      this.appState.selectedLanguage.value$.subscribe((language: Language) => {
        if (language.shortKey) {
          this.setLanguage(language.shortKey);
          moment.updateLocale(
            this.currentLanguage.shortKey,
            LanguageConstants.getMomentDateFormat(this.currentLanguage.shortKey)
          );
        }
      })
    );
    return Promise.resolve();
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public setLanguage(language: Language | string): void {
    if (typeof language === 'string') {
      language = this.getLanguage(language as string);
    }

    this.translate.use(language.shortKey).pipe(take(1)).subscribe();

    this.currentLanguage = language;
  }

  public getLanguage(key: string): Language {
    const lookupKey = key.length === 2 ? 'shortKey' : 'longKey';

    for (const language of appLanguages) {
      if (language[lookupKey] === key) {
        return language;
      }
    }

    // set default if not found
    moment.locale(this.defaultCulture);
    const lang = this.getLanguage(this.defaultCulture);
    this.appState.selectedLanguage.value = lang;
    return lang;
  }

  public getText(key: string, interPolateParams?: any): string {
    return this.translate.instant(key, interPolateParams);
  }

  public get(arr: string[]) {
    return this.translate.get(arr);
  }

  public hasTranslation(key: string): boolean {
    const translation = this.translate.instant(key);
    return translation !== key && translation !== '';
  }
}
