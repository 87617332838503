<button #settingsBtn="matMenuTrigger" class="settings-btn" mat-icon-button [matMenuTriggerFor]="settings">
  <mat-icon>settings</mat-icon>
</button>
<mat-menu #settings="matMenu" [overlapTrigger]="false" yPosition="below" class="menu-pane">
  <button mat-menu-item *ngIf="showSelectFarm$ | async" (click)="selectCvr()">
    <div class="btn-content">
      {{ 'settings.selectFarm' | translate }}
      <mat-icon>swap_horiz</mat-icon>
    </div>
  </button>
  <button mat-menu-item (click)="goToDelegation()">
    <div class="btn-content">
      <p>{{ 'settings.delegation' | translate }}</p>
      <mat-icon>supervisor_account</mat-icon>
    </div>
  </button>
  <button mat-menu-item (click)="signOut()">
    <div class="btn-content">
      <p>{{ 'settings.logOut' | translate }}</p>
      <mat-icon>logout</mat-icon>
    </div>
  </button>
  <div class="app-info">
    <p>{{ user?.displayName }}</p>
    <p>{{ 'common.appVersion' | translate }} {{ versionnumber }}</p>
  </div>
</mat-menu>