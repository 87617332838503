import { Component, Inject } from '@angular/core';
import { AuthorizationService } from 'src/app/shared/services/authorization/authorization.service';
import { LocalStorageService } from 'src/app/shared/services/storage/local-storage/local-storage.service';
import { APP_CONFIG } from 'src/config/consts/app-config.token';
import { WINDOW_REF } from 'src/config/consts/window-ref.token';
import { AppConfig } from 'src/config/model/app-config.interface';
import { WindowRef } from 'src/config/model/window-ref.interface';
import { SWITCH_USER_REQUEST } from '../logged-out/logged-out.component';

@Component({
  selector: 'app-not-validated',
  templateUrl: './not-validated.component.html',
  styleUrls: ['./not-validated.component.scss']
})
export class NotValidatedComponent {

  constructor(
    @Inject(WINDOW_REF) private windowRef: WindowRef,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private auth: AuthorizationService,
    private storage: LocalStorageService) { }

  switchUser() {
    this.storage.setItem(SWITCH_USER_REQUEST, true);
    this.auth.signOut();
  }

  goToMyProfile() {
    this.windowRef.open(this.appConfig.config.myProfileUrl, '_blank');
  }

  goToOrderSubscription() {
    // Open subscription page in same window and tab as this app, 
    // otherwise user could be stranded on not-validated page without any clue of whart to do next.
    this.windowRef.location.replace(this.appConfig.config.subscriptionUrl);
  }

  goToDelegationHelp() {
    this.windowRef.open('https://help.seges.dk/agroid/validering-af-cvr-nummer-og-cpr-nummer-med-personligt-nem-id', '_blank');
  }
}
