import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppUser } from 'src/app/shared/models/misc/app-user.interface';
import { AuthorizationService } from 'src/app/shared/services/authorization/authorization.service';
import { AppStateService } from 'src/app/shared/state/app-state/app-state.service';
import { APP_CONFIG } from 'src/config/consts/app-config.token';
import { WINDOW_REF } from 'src/config/consts/window-ref.token';
import { AppConfig } from 'src/config/model/app-config.interface';
import { WindowRef } from 'src/config/model/window-ref.interface';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsComponent implements OnInit {
  showSelectFarm$: Observable<boolean>;
  @Input() user: AppUser;
  versionnumber: string;

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    @Inject(WINDOW_REF) private windowRef: WindowRef,
    private auth: AuthorizationService,
    private appState: AppStateService,
    private router: Router) { }

  ngOnInit(): void {
    this.versionnumber = this.appConfig?.config?.versionnumber;
    this.assignObservables();
  }

  goToDelegation() {
    this.windowRef.open(this.appConfig?.config?.delegationUrl, '_blank');
  }

  async selectCvr() {
    this.appState.userDelegations.value = [];
    this.router.navigate(['/app/delegation']);
  }

  signOut() {
    this.auth.signOut();
  }

  private assignObservables() {
    this.showSelectFarm$ = combineLatest([
      this.appState.userDelegations.value$,
      this.appState.delegatedUserState.value$,
      this.appState.userState.value$]).pipe(
        map(([delegations, delegatedUser, user]) => user?.isAdmin || !!delegatedUser || !!delegations?.length)
      );
  }
}
