<div class="wrap">
  <div class="nav-head">
    <button lf-trans-btn id="closeMenuBtn" (click)="menuItemPressed.emit()">
      <p>{{'common.close' | translate | uppercase }}</p>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <button class="nav-btn" *ngFor="let nav of navigationItems" (click)="navigate(nav)">
    <div class="btn-content">
      <p [innerHTML]="nav.title | translate | uppercase "></p>
      <mat-icon *ngIf="nav.icon">{{nav.icon}}</mat-icon>
    </div>
  </button>
</div>
