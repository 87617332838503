import * as moment from 'moment';

export class LanguageConstants {
  public static getMomentDateFormat(languageShortKey: string) {
    switch (languageShortKey) {
      case 'da':
        return {
          LT: 'HH:mm',
          LTS: 'HH:mm:ss',
          L: 'DD-MM-YYYY',
          LL: 'D. MMMM YYYY',
          LLL: 'D. MMMM YYYY HH:mm',
          LLLL: 'dddd [d.] D. MMMM YYYY [kl.] HH:mm',
          l: 'dd-MM',
          ll: 'DD-MM-YYYY HH:mm',
          longDateFormat: {
            LT: 'HH:mm',
            LTS: 'HH:mm:ss',
            L: 'DD-MM-YYYY',
            LL: 'D. MMMM YYYY',
            LLL: 'D. MMMM YYYY HH:mm',
            LLLL: 'dddd [d.] D. MMMM YYYY [kl.] HH:mm',
            l: 'DD-MM-YYYY',
            ll: 'DD-MM-YYYY HH:mm'
          }
        } as moment.LocaleSpecification;
      case 'en':
        return {
          LT: 'HH:mm',
          LTS: 'HH:mm:ss',
          L: 'DD/MM/YYYY',
          LL: 'D MMMM YYYY',
          LLL: 'D MMMM YYYY HH:mm',
          LLLL: 'dddd, D MMMM YYYY HH:mm',
          l: 'dd/MM',
          ll: 'DD/MM/YYYY HH:mm',
          longDateFormat: {
            LT: 'HH:mm',
            LTS: 'HH:mm:ss',
            L: 'DD/MM/YYYY',
            LL: 'D MMMM YYYY',
            LLL: 'D MMMM YYYY HH:mm',
            LLLL: 'dddd, D MMMM YYYY HH:mm',
            l: 'DD/MM/YYYY',
            ll: 'DD/MM/YYYY HH:mm'
          }
        } as moment.LocaleSpecification;
      default:
        // da-dk
        return {
          LT: 'HH:mm',
          LTS: 'HH:mm:ss',
          L: 'DD-MM-YYYY',
          LL: 'D. MMMM YYYY',
          LLL: 'D. MMMM YYYY HH:mm',
          LLLL: 'dddd [d.] D. MMMM YYYY [kl.] HH:mm',
          l: 'dd-MM',
          ll: 'DD-MM-YYYY HH:mm',
          longDateFormat: {
            LT: 'HH:mm',
            LTS: 'HH:mm:ss',
            L: 'DD-MM-YYYY',
            LL: 'D. MMMM YYYY',
            LLL: 'D. MMMM YYYY HH:mm',
            LLLL: 'dddd [d.] D. MMMM YYYY [kl.] HH:mm',
            l: 'DD-MM-YYYY',
            ll: 'DD-MM-YYYY HH:mm'
          }
        } as moment.LocaleSpecification;
    }
  }

  constructor() {}
}
