import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { entries, groupBy, sumBy } from 'lodash-es';
import { filter, finalize, take } from 'rxjs/operators';
import { StateItem } from 'src/app/libraries/seges-web-utils/state-item/state-item.class';
import { EmissionResult } from 'src/app/shared/models/dto/emission-result.interface';
import { EmissionSourceResult } from 'src/app/shared/models/dto/emission-source-result.interface';
import { EmissionDetailsResponse } from 'src/app/shared/models/responses/emission-details-response.interface';
import { EmissionRepo } from 'src/app/shared/repos/emission/emission.repo';
import { HttpClientService } from 'src/app/shared/services/http-client/http-client.service';
import { LocalStorageService } from 'src/app/shared/services/storage/local-storage/local-storage.service';
import { AppStateService } from 'src/app/shared/state/app-state/app-state.service';
import { DataStateService } from 'src/app/shared/state/data-state/data-state.service';
import { EmissionLinksMap } from '../climate-footprint/emissions/model/emission-links-map.const';
import { EmissionCategories } from '../climate-footprint/model/emission-categories.enum';
import { EmissionSourceResultVM } from '../climate-footprint/model/emission-source-result-vm.interface';
import { EmissionTypes } from '../climate-footprint/model/emission-types.enum';

@Component({
  selector: 'app-frontpage',
  templateUrl: './frontpage.component.html',
  styleUrls: ['./frontpage.component.scss']
})
export class FrontpageComponent implements OnInit {
  emissions = new StateItem<EmissionDetailsResponse>(null, 'EMISSIONS', this.localStorage);
  totalScenarioCo2e: number;
  emil:EmissionSourceResultVM[];
  
  public barChartData: ChartDataset[] = [
    { data: [], label: 'Standard', backgroundColor: 'rgb(180 202 210)', hoverBackgroundColor: '#b4cad2e6' },
    { data: [], label: 'Bedriften', backgroundColor: 'rgb(7 100 113)', hoverBackgroundColor: '#076471e6' },
    { data: [], label: 'Scenarier', backgroundColor: 'rgb(220 235 240)', hoverBackgroundColor: '#dcebf0e6' }
  ];

  

  public barChartOptions: ChartOptions = {
    responsive: true,

    scales: {
      y: {
        title: {
          display: true,
          text: "Ton CO₂e",
          font: {
            family: "Arial"
          }
        }
      }
    },
    plugins: {
      tooltip: {
        enabled: true,
        borderWidth: 0,
        callbacks: {
          label: function(context:any) {
           return  context.raw.toFixed(1) + ' ton CO₂e'
          },
          
          title: function(context) {
            let title = context[0].label + ' - ' + context[0].dataset.label;
            return title
          },
          labelColor: function(context) {
            let color: string = JSON.stringify(context.dataset.backgroundColor)         
            return {
                borderColor: 'rgb(7 100 113 / 0%)',
                backgroundColor: color.substring(1, color.length-1),
                borderWidth: 0,
                borderRadius: 0,
            };
        },
        }
      }
    }
    
  };
  public barChartLabels = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [
  
  ];

  public yearScope$ = this.appState.selectedYearScope.value$;

  constructor(
    private appState: AppStateService,
    private dataState: DataStateService,
    private emissionRepo: EmissionRepo,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute,
    private http: HttpClientService,
  ) {
  }

  ngOnInit(): void {
    this.appState.loading.value = true;
    this.dataState.initializeData();
    this.initData();
  }

  initData() {
    this.dataState.businessEmission.value$.pipe(filter(be => be !== null), take(1)).subscribe((args) => {
      this.emissionRepo.getEmissionDetails(args.businessEntityId, args.climateFootPrintId)
        .pipe(take(1), finalize(() => this.appState.loading.value = false))
        .subscribe((emissions) => {
          this.emissions.value = emissions;
          let tags = [];
          this.dataState.epicEmissionTags$.subscribe(res => tags = res);

          const parentEmissions = this.flattenEmissions(emissions.emissionSourceResults, tags).filter(e => e.isEpic).map(p => p.emissions);
          const flatEmissions = [].concat.apply([], parentEmissions);
          const catGroup = groupBy(flatEmissions, (e:any) => e.emissionCategory);
          const grouped = {};
           entries(catGroup).forEach(([key, val]) => {
            grouped[key] = groupBy(val, (e:any) => e.emissionType);
          });

          let Standard = []
          let adjusted = []
          let scenario = []

          this.emissions.value.emissionSourceResults.forEach(r => {
            this.barChartLabels.push(r.text)
            r.emissions.forEach(emission => {
              if(emission.text === 'CO2e' && emission.emissionCategory === "Standard") Standard.push((emission.emissionValue / 1000))
              if(emission.text === 'CO2e' && emission.emissionCategory === "Adjusted") adjusted.push((emission.emissionValue / 1000))
              if(emission.text === 'CO2e' && emission.emissionCategory === "Scenario") scenario.push((emission.emissionValue / 1000))
            })
          })
          this.barChartData[0].data = Standard;
          this.barChartData[1].data = adjusted;
          this.barChartData[2].data = scenario;
    
        this.totalScenarioCo2e = sumBy(grouped[EmissionCategories.Scenario]?.[EmissionTypes.CO2e], (e: EmissionResult) => e.emissionValue);
        });
    });
  }

  flattenEmissions(emissions: EmissionSourceResult[], epicEmissionTags: string[]): EmissionSourceResultVM[] {
    let toReturn = [];
    emissions.forEach(esr => {
      let nestedMapped = [];
      esr.emissionSourceResults?.forEach(essr => {
        const canExpand = essr.emissionParameterResults?.length > 1;
        nestedMapped = [
          ...nestedMapped,
          {
            ...essr,
            isEmissionParam: false,
            hasInputs: !!EmissionLinksMap[essr.tag],
            linkTag: EmissionLinksMap[essr.tag],
            expanded: canExpand,
            isEpic: false,
            canExpand
          },
          ...essr.emissionParameterResults?.map(esrr => ({ ...esrr, isEmissionParam: true, expanded: canExpand })),
        ];
      });
      const mapped = [
        {
          ...esr,
          hasInputs: epicEmissionTags.includes(esr.tag),
          linkTag: EmissionLinksMap[esr.tag] ?? esr.tag,
          isEmissionParam: false,
          expanded: true,
          isEpic: true
        },
        ...nestedMapped
      ];
      toReturn = [...toReturn, ...mapped];
    });
    return toReturn;
  }
}
